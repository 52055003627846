import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import PublicLayout from "../components/PublicLayout"
import {
  Row,
  Col,
  Layout,
} from "antd"
import SEO from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { handlers } from "../helpers/handlers"
import NewBranchOffice from '../images/NewBranchOffice.jpg'

const { Content } = Layout

const NewBranchOfficesAdversiting = (props) => {
  const {
    data: {
      wpgraphql: {
        settings,
      },
    },
  } = props
  const { t } = useTranslation()
  const contextType = props.pageContext.type


  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "44540453",
          formId: "245bf5d9-694b-408d-8a76-9cbf46ed345d",
          target: '#hubspotForm',
        })
      }
    })
  }, [])


  const schemaExtraData = [{
    "@type": "Product",
    logo: "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    sameAs: [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador",
    ],
    "@context": "Schema.org - Schema.org ",
    "@type": "Product",
    "name": "Valdivia y Talca ¡Bienvenidos a la red de centros médicos de Centro Médico del Trabajador! | CMT Salud",
    "image": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "description": "Conoce las nuevas sucursales del Centro Médico del Trabajador CMT Salud en Valdivia y Talca, para realizar exámenes preocupacionales a los trabajadores",
    "brand": {
      "@type": "Brand",
      "name": "CMT Salud"
    },
  }]


  return (
    <PublicLayout>
      <SEO
        title={contextType === 'form' ? t('seo.newBranchOfficesAdversiting.title') : t('seo.newBranchOfficesAdversiting.successTitle')}
        description={contextType === 'form' ? t('seo.newBranchOfficesAdversiting.description') : t('seo.newBranchOfficesAdversiting.descriptionSuccess')}
        schemaExtraData={schemaExtraData}
        dataLayer={{
          userId: "",
          pageId: "",
          pageType: "",
          category: "",
        }}
      />
      <Layout className="new-branch-offices-container">
        <Content>
          <Row justify="center">
            <Col lg={22} xs={22}>
              <Row justify="center" gutter={[40, 20]} className="mt-lg-80 mt-md-40 mt-sm-40 mt-xs-40 mb-lg-80 mb-md-40 mb-sm-40 mb-xs-40">
                <Col lg={11} xs={24}>
                  <Row gutter={[0, 30]}>
                    <Col lg={24} xs={24}>
                      <h1>{t('newBranchOfficesAdversiting.title')}</h1>
                      <div dangerouslySetInnerHTML={{
                        __html
                          : `${t('newBranchOfficesAdversiting.mainContent')}`
                      }} />
                    </Col>
                    <Col lg={24} xs={24} className="branch-office-image-wrapper">
                      <img
                        src={NewBranchOffice}
                        alt="Nuevas sucursales"
                        loading="lazy"
                        aria-label="Nuevas sucursales"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={13} xs={24}>
                  <Row justify="center" gutter={[0, 60]}>
                    <Col lg={24} xs={24}>
                      <Row
                        className="form-wrapper
                        pl-lg-50 pl-md-20 pl-sm-20 pl-xs-20
                        pr-lg-50 pr-md-20 pr-sm-20 pr-xs-20
                        pt-40 pb-20
                        "
                        gutter={[0, 20]}
                      >
                        {
                          contextType === 'form' ?
                            <>
                              <Col lg={24} xs={24}>
                                <h2>{t('newBranchOfficesAdversiting.formTitle')}</h2>
                              </Col>
                              <Col lg={24} xs={24}>
                                <div id="hubspotForm" />
                              </Col>
                            </>
                            :
                            <>
                              <Col lg={24} xs={24}>
                                <h2 className="mb-20">{t('newBranchOfficesAdversiting.successTitle')}</h2>
                              </Col>
                              <Col lg={24} xs={24}>
                                <p className="mb-20">{t('newBranchOfficesAdversiting.successText')}</p>
                              </Col>
                            </>
                        }
                      </Row>
                    </Col>
                    <Col lg={20} xs={24}>
                      <Row justify="center" gutter={[20, 20]}>
                        <Col lg={8} xs={24}>
                          <Row justify="center">
                            <Col
                              lg={2}
                              xs={24}
                              className="mb-15"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span className="icon-system-phone" />
                            </Col>
                            <Col lg={24} xs={24}>
                              <p className="text-center mb-5">
                                {t('newBranchOfficesAdversiting.centralPhone')}
                              </p>
                            </Col>
                            <Col lg={24} xs={24}>
                              {
                                settings.nodes.length > 0 &&
                                <a
                                  href={`tel:${settings.nodes[0].fields.centralPhone}`}
                                >
                                  <p className="text-center">
                                    {
                                      handlers.splitPhone(settings.nodes[0].fields.centralPhone)
                                    }
                                  </p>
                                </a>
                              }
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={8} xs={24}>
                          <Row justify="center">
                            <Col
                              lg={2}
                              xs={24}
                              className="mb-15"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span className="icon-system-placeholder" />
                            </Col>
                            <Col lg={24} xs={24}>
                              <p className="text-center mb-5">
                                {t('newBranchOfficesAdversiting.branchOffice')}
                              </p>
                            </Col>
                            <Col lg={24} xs={24}>
                              <Link
                                to={`/${t('newBranchOfficesAdversiting.branchOffice').toLocaleLowerCase()}/`}
                                replace
                              >
                                <p className="text-center">
                                  Ver {t('newBranchOfficesAdversiting.branchOffice').toLocaleLowerCase()}
                                </p>
                              </Link>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={8} xs={24}>
                          <Row justify="center" gutter={[10, 10]}>
                            <Col
                              className="mb-15"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <a
                                className="mr-15"
                                href={settings.nodes[0].fields.facebook}
                                target="_blank"
                                rel="noreferrer"
                                aria-label="Facebook"
                              >
                                <span className="icon-brand-facebook" />
                              </a>
                            </Col>
                            <Col
                              className="mb-15"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <a
                                href={settings.nodes[0].fields.linkedin}
                                target="_blank"
                                rel="noreferrer"
                                aria-label="Linkedin"
                              >
                                <span className="icon-brand-linkedin" />
                              </a>
                            </Col>
                            <Col lg={24} xs={24}>
                              <p className="text-center">{t('newBranchOfficesAdversiting.followUsTitle')}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  )
}

export default NewBranchOfficesAdversiting


export const postQuery = graphql`
  query GET_NEW_BRANCH_OFFICES_DATA($country: String, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpgraphql {
      settings(where: { country: $country }) {
        nodes {
          fields {
            centralPhone
            centralEmail
            facebook
            linkedin
          }
        }
      }
    }
  }
`